import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';


const Header = (props) => {
    const [isActive, setActive] = useState(false);
    const [classActive, setClassActive] = useState('');
    const [isActiveContact, setActiveContact] = useState(false);

    useEffect(() => {
        setClassActive(isActive ? 'active' : '');
        setOverflow(isActive);
    }, [isActive]);

    const setOverflow = (hidden) => {
        if (hidden){
            document.body.style.overflowY = 'hidden';
            document.body.style.height = '100%';
        }else{
            document.body.style.overflowY = 'visible';
            document.body.style.height = 'unset';
        }
    }

    const handleAdvance = () => {
        setActive(!isActive);
    };

    const closeMenu = () => {
        setActive(!isActive);
    }
    const closeMenuMobile = () => {
        setActive(!isActive);
        handleScroll()
    }

    function handleScroll() {
        window.scroll({
        top: document.body.offsetHeight - 800,
        left: 0, 
        behavior: 'smooth',
    });
  }

    return(
        <header className={ `${classActive}`}>
            <Link to="/" className="logo">
                <img src={props.logo} alt="MDS" />
            </Link>
            <div className='menu_box'>
                <nav>
                    <label htmlFor='drop' className='toggle'>
                        <span className='open' onClick={ handleAdvance }>&#9776;</span>
                    </label>
                    <input type='checkbox' id='drop' />
                    <ul className='menu'>
                        <li>
                            <a href="#contactUS">
                                Contact Us
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="menu_mobile">
                <label htmlFor='drop' className='toggle'>
                    <span className='closed' onClick={ handleAdvance }></span>
                </label>
                <ul className='menu'>
                    <li>
                        <Link to={"/"} onClick={closeMenu}>
                            Home
                        </Link>   
                    </li>
                    <li>
                        <div onClick={closeMenuMobile}>
                            Contact Us
                        </div>
                    </li>
                </ul>
                <div className='social'>
                    <a title='' aria-hidden='true' href='https://www.facebook.com/weareMDS' target='_blank' className='fb' rel='noopener noreferrer' />
                    <a title='' aria-hidden='true' href='https://co.linkedin.com/company/mds-digital' target='_blank' className='lin' rel='noopener noreferrer' />
                    <a title='' aria-hidden='true' href='https://www.instagram.com/mdsdigitalagencia' target='_blank' className='ins' rel='noopener noreferrer' />
                </div>
            </div>
            
        </header>
    );
        
}

export default Header;