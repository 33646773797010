import React, {useEffect} from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import BannerIntern from '../components/bannerIntern'
import InfoRowRight from '../components/infoRowRight'
import InfoRowLeft from '../components/infoRowLeft'
import ContactForm from '../components/contactForm'

const width = window.innerWidth;
const breakpoint = 620;

function Miller() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">
      <Header
        logo={require("../assets/logo.png")}
      />
      <BannerIntern
        bgBanner={width < breakpoint ? require("../assets/banner/banner-miller-mobile.png") : require("../assets/banner/banner-miller-interna.png")}
        logoBanner={require("../assets/logoBanner/timelessLockupStacked.png")}
      />
      <div className="section-1 mt-5 mb-5">
        <div className="container-fluid">
          <InfoRowRight
            infoVideoR={"https://www.youtube.com/embed/PsdeFYOPe7A?si=dtDbp1CwkaiXFF29"}
            infoTitleR="Problem"
            infoTextR="Develop IOT (Internet Of Things) technology, which will allow a set of custom made gold rings to detect when they are next to each other and when they are, give the participant a $10,000 digital card added directly to their cell phone using either Apple or Google Pay."
          />

          <InfoRowLeft
            infoImgL={require("../assets/miller-lite-interna.jpg")}
            infoTitleL="Solution"
            infoTextL={`By doing extensive research, the MDS R&D team decided to use NFC technology by using tags within each of the rings and geo-location data from their devices to determine the users location. We worked directly with the jeweler to make sure the ring was designed in a way where the tag would fit and work perfectly. <br /><br />
            Once all the users are together by knowing their location, a $10,000 digital card will be issued directly to the users’ iWallet.
            We used Stripe as a credit card processor and worked directly with Apple to enable the application to issue the digital card, on the fly.`}
          />

          <InfoRowRight
            infoVideoR={"https://player.vimeo.com/video/673591872?h=3d03d0833a"}
            infoTitleR="Result"
            infoTextR="We created an application which was to be used only by 6 people in the world since the only way to unlock it is by using the rings provided to the winner. <br /><br />
            There was major buzz around the campaign and the winner is set to receive the rings early 2022."
          />
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Miller;
