import React from "react";
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

const InfoRowRight = (props) => {

    AOS.init();
    return(
        <div className={`row ${props.bgColor}`}data-aos="fade-right" data-aos-offset="300">
            <div className="col-lg-6 col-sm-6 vertical-center">
                <div className="img-section">
                    {props.infoImgR === undefined ?
                       <></>
                    :
                        <img src={props.infoImgR} alt="MDS" />
                    }
                    {props.infoVideoR === undefined ?
                       <></>
                    :
                        <div className="contentVideo">
                            <iframe 
                                src={props.infoVideoR}
                                width="640" 
                                height="360" 
                                frameborder="0" 
                                allow="autoplay; fullscreen; picture-in-picture" 
                                allowfullscreen
                                title="contentVideo"
                            >
                            </iframe>
                        </div>
                    }
                </div>
            </div>
            <div className="col-lg-6 col-sm-6 vertical-center">
                <div className="contentInfo">
                    <Link className="link_animation" to={props.linkSkill}>
                        <h1 className="section-titles skills">{props.infoTitleR}</h1>
                    </Link>
                    <p className="infoSkill" dangerouslySetInnerHTML={{__html: props.infoTextR}}></p>
                </div>
            </div>
        </div>
    );
        
}

export default InfoRowRight;