import React from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

const InfoRowLeft = (props) => {

    AOS.init();
    return(
        <div className="row" data-aos="fade-left" data-aos-offset="300">

            <div className="col-lg-6 col-sm-6 vertical-center mobile">
                <div className="img-section">
                    <img className="float-right" src={props.infoImgL} alt="MDS" />
                </div>
            </div>
            
            <div className="col-lg-6 col-sm-6 vertical-center">
                <div className="contentInfo">
                    <h1 className="section-titles int">{props.infoTitleL}</h1>
                    <p dangerouslySetInnerHTML={{__html: props.infoTextL}}></p>
                </div>
            </div>

            <div className="col-lg-6 col-sm-6 vertical-center desktop">
                <div className="img-section">
                    <img className="float-right" src={props.infoImgL} alt="MDS" />
                </div>
            </div>
        </div>
    );
        
}

export default InfoRowLeft;