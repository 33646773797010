import React, {useEffect} from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import BannerIntern from '../components/bannerIntern'
import InfoRowRight from '../components/infoRowRight'
import InfoRowLeft from '../components/infoRowLeft'
import ContactForm from '../components/contactForm'

const width = window.innerWidth;
const breakpoint = 620;

function Miller() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">
      <Header
        logo={require("../assets/logo.png")}
      />
      <BannerIntern
        bgBanner={width < breakpoint ? require("../assets/banner/banner-starburst-mobile.jpg") : require("../assets/banner/banner-starburst.jpg")}
        logoBanner={require("../assets/empty.png")}
      />
      <div className="section-1 mt-5 mb-5">
        <div className="container-fluid">
          <InfoRowRight
            infoImgR={require("../assets/starburst.jpg")}
            infoTitleR="Problem"
            infoTextR="Since no one on earth can answer the question of Why is Starburst so Juicy, the decision was made to ask beings from other planets. How can we communicate with beings from other parts of the universe to seek the answer to this question?."
          />

          <InfoRowLeft
            infoImgL={require("../assets/Starburst_Mobile_3.jpg")}
            infoTitleL="Solution"
            infoTextL={`The social team will ask TikTok users to answer the question #WhyIsStarburstSoJuicy and based on that hashtag, we will look for those videos, where a select few will be sent to space by using different transmitters located around the world, we will beam those selected videos to space hoping that a more advanced civilization will answer this age old question, Why is Starburst so Juicy?`}
          />

          <InfoRowRight
            infoVideoR={"https://www.youtube.com/embed/YblCCAxt69k?si=vs8TLKc-wSo2PxlT"}
            infoTitleR="Result"
            infoTextR="We beamed dozens of videos into outer space and tracked each one of them as they made their journey into space. The site we created was able to measure the exact distance of each of the videos from earth and track each of them in real time.  
            All the videos are currently traversing through the galaxy, and there remains a possibility that some entity out there may respond."
          />
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Miller;
