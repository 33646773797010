import React from "react";
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';

const bannerSuccessStoriesR = (props) => {
   
    AOS.init();
    return(
        <section className={"rowInfo " + props.classCut} data-aos={props.fade_direction} data-aos-offset="450" data-aos-easing="ease-in-sine" style={{ backgroundImage:`url(${props.bgBannerProduct})` }}>
            <div className={"info-cont"}     
                data-aos="fade-up"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="1000"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor-placement="top-center"
            >
                <img className={props.classNameImg} src={props.infoLogoProduct} alt="MDS" />
                <p>
                    {props.infoText}
                </p>
                {props.productLink === '' ?
                    <></>
                :
                    <Link to={props.productLink}>
                        {props.infoLink}
                    </Link>
                }
            </div>
        </section>
    );
        
}

export default bannerSuccessStoriesR;