import React, {useEffect} from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import BannerIntern from '../components/bannerIntern'
import InfoRowRight from '../components/infoRowRight'
import InfoRowLeft from '../components/infoRowLeft'
import ContactForm from '../components/contactForm'

const width = window.innerWidth;
const breakpoint = 620;

function Skittles() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">
      <Header
        logo={require("../assets/logo.png")}
      />
      <BannerIntern
        className="skittles"
        bgBanner={width < breakpoint ? require("../assets/banner/banner-skittles-mobile.png") : require("../assets/banner/banner-skittles-interna.jpg")}
        logoBanner={require("../assets/logoBanner/skittles-brand.png")}
      />
      <div className="section-1 mt-5 mb-5">
        <div className="container-fluid">

          <InfoRowRight
            infoImgR={require("../assets/img-1-contenido-skittles.png")}
            infoTitleR="Problem"
            infoTextR="Creating the technology that will allow Skittles to apologize to all their users for removing the Skittles Lime flavor over 9 years ago.<br /><br />
            By using social listening, we’ll know which users we need to apologize to and send them a customized tweet.<br /><br />
            Those users who respond by signing up, will be part of a massive surprise coming on March 2022!"
          />

          <InfoRowLeft
            infoImgL={require("../assets/img-contenidos-interna-skittles-2024-min.jpg")}
            infoTitleL="Solution"
            infoTextL="We created a twitter bot which will will scan all the tweets of those users who tweeted about the Lime Skittle for any inappropriate content. Only those that pass the content validation, will receive a response back directly from the @skittles account.<br /><br />
            The tweet will contain a link, where the user will be able to confirm their identity and confirm that they want to participate. In return, each participating user will be given a free bag of Skittles."
          />

          <InfoRowRight
            infoVideoR={"https://www.youtube.com/embed/fUbKSOR3KxE?si=_NHzVGgNOAx01RUy"}
            infoTitleR="Result"
            infoTextR="Over 65,000 tweets were created by the users, more than 900,000 final tweets will be analyzed for content and a big surprise will come out at the end of the campaign in early March.<br /><br />
            This campaign is currently running."
          />
          <ContactForm />

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Skittles;
