import React, {useState} from "react";

const ItemSlider = (props) => {
    const [isActive, setActive] = useState(false);

    const toggleVideo = () => {
        setActive(!isActive);
    };

    return(
        <div className="item_slider container_box">
        {props.typeProjectSlider === 'image' ? 
            <div className="item_slider" style={{backgroundImage: `url(${props.imageProjectSlider})`, backgroundPosition: `center right`, backgroundRepeat: `no-repeat`, backgroundSize: `cover`}}>
                <div className="description_box image">
                    <h3 dangerouslySetInnerHTML={{__html: props.titleProjetSlider}}></h3>
                    <p>{props.descriptionProjetSlider}</p>
                    <p>{props.descriptionProjetSlider2}</p>
                </div>
            </div>
        : <></>}
        {props.typeProjectSlider === 'video' ?
            <div className={ `item_slider video`} style={{background: `url(${props.thumbProjectSlider})`, backgroundPosition: `center`, backgroundRepeat: `no-repeat`}}>
                <div className="play_btn" onClick={toggleVideo}></div>
                <video width="400" loop="true" autoPlay muted playsInline defaultMuted>
                    <source src={props.imageProjectSlider} type="video/mp4" />
                </video>
                <div className="description_box video">
                    <h3>{props.titleProjetSlider}</h3>
                    <p>{props.descriptionProjetSlider}</p>
                </div>
            </div>
        : <></>}
        </div>
    );
        
}

export default ItemSlider;