import React from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

const Awards = (props) => {

    AOS.init();
    return(
        <div className='boxAwards'>
            <h2>Awards & Partnerships</h2>
            <div className='row boxAwardsInfo'>
                <div className='boxAwardsInfoImage'>
                    <img src={require("./../assets/prize1.png")} alt="" />
                    <h3>Toyota | Todo o Nada</h3>
                    <div className='row'>
                        <div className='col-lg-3 col-sm-12'>
                            Clio Music Bronze
                            <span>Emerging Technology</span>
                        </div>
                        <div className='col-lg-3 col-sm-12'>
                            Clio Music Bronze
                            <span>Innovation</span>
                        </div>
                        <div className='col-lg-3 col-sm-12'>
                            Clio Music Bronze
                            <span>Partnerships & Collaborations</span>
                        </div>
                    </div>
                </div>
                <div className='boxAwardsInfoImage'>
                    <img src={require("../assets/prize2.png")} alt="" />
                    <h3>Skittles | Apologize the Rainbow</h3>
                    <div className='row'>
                        <div className='col-lg-3 col-sm-12'>
                            GOLD LION
                            <span>Emerging Technology</span>
                        </div>
                        <div className='col-lg-3 col-sm-12'>
                            GOLD LION
                            <span>Innovation</span>
                        </div>
                        <div className='col-lg-3 col-sm-12'>
                            SILVER LION
                            <span>Partnerships & Collaborations</span>
                        </div>
                        <div className='col-lg-3 col-sm-12'>
                            BRONZE LION
                            <span>Partnerships & Collaborations</span>
                        </div>
                    </div>
                </div>
                <div className='boxAwardsInfoImage'>
                    <img src={require("../assets/prize3.png")} alt=""/>
                    <h3>Federación Cardiologica Argentina | Cancelen el Mundial</h3>
                    <div className='row'>
                        <div className='col-lg-3 col-sm-12'>
                            BEST OF SHOW
                            <span>National ADDY</span>
                        </div>
                        <div className='col-lg-3 col-sm-12'>
                            BRONZE AWARD
                            <span>New York Festival</span>
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            GOLD FOR HEALTHCARE CAMPAIGN OF THE YEAR
                            <span>AdAge Small Agency Awards</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
        
}

export default Awards;