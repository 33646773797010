import React, {useEffect} from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import BannerIntern from '../components/bannerIntern'
import InfoRowRight from '../components/infoRowRight'
import InfoRowLeft from '../components/infoRowLeft'
import ContactForm from '../components/contactForm'

const width = window.innerWidth;
const breakpoint = 620;

function Toyota() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">
      <Header
        logo={require("../assets/logo.png")}
      />
      <BannerIntern
        className="toyotaLogo"
        bgBanner={width < breakpoint ? require("../assets/banner/banner-toyota-mobile.png") : require("../assets/banner/banner-toyota-interna.jpg")}
        logoBanner={require("../assets/logoBanner/logo-toyota.png")}
      />
      <div className="section-1 mt-5 mb-5">
        <div className="container-fluid">

          <InfoRowRight
            infoImgR={require("../assets/img-1-contenido-toyota.jpg")}
            infoTitleR="Problem"
            infoTextR="Create an engaging and unique digital experience for the launch of the new Toyota Apex using artificial intelligence and leveraging the relationship with Reggaeton artists Lunay and Anitta."
          />

          <InfoRowLeft
            infoImgL={require("../assets/img-2-contenido-toyota.jpg")}
            infoTitleL="Solution"
            infoTextL="MDS developed an interactive, mobile and artificial intelligence driven experience where the user simply by selecting which character they wanted to become and uploading a selfie, the system would inject them as one the characters in the video.<br /><br />
            This process was done completely seamlessly and our AI algorithm was able to “merge” the users face with our characters face flawlessly."
          />

          <InfoRowRight
            infoVideoR={"https://player.vimeo.com/video/673592093?h=a17c5e0b13"}
            infoTitleR="Result"
            infoTextR="The experience was a success receiving acclaimed comments by the creative community and media publications. Thousands of videos were created and tens of thousands of organic user reached impressions and shares were made.<br /><br />
            The music video (which was created specifically for this campaign), became the official music video for Lunay and Anittas’ “Todo o Nada” collaboration."
          />
          <ContactForm />

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Toyota;
