import React , {useEffect}from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import BannerIntern from '../components/bannerIntern'
import InfoRowRight from '../components/infoRowRight'
import InfoRowLeft from '../components/infoRowLeft'
import ContactForm from '../components/contactForm'

const width = window.innerWidth;
const breakpoint = 620;

function Gatorade() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="App">
      <Header
        logo={require("../assets/logo.png")}
      />
      <BannerIntern
        className="gatoradeLogo"
        bgBanner={width < breakpoint ? require("../assets/banner/banner-gatorade-mobile.png") : require("../assets/banner/banner-gatorade-interna.png")}
        logoBanner={require("../assets/logoBanner/logo-gatorade.png")}
      />
      <div className="section-1 mt-5 mb-5">
        <div className="container-fluid">

          <InfoRowRight
            infoImgR={require("../assets/img-1-contenido-gatorade.png")}
            infoTitleR="Problem"
            infoTextR="Find a way to evolve the way that previous marketing campaigns were done by using technology which is broadly available across Centran and South America."
          />

          <InfoRowLeft
            infoImgL={require("../assets/img-2-contenido-gatorade.png")}
            infoTitleL="Solution"
            infoTextL="On previous years, these promotions were used by creating a microsite where users could register to participate. Considering that in most countries in Central and South America mobile data costs are still high (and in some cases most people don’t have a data plan), we created a WhatsApp bot where the entire registration process took place.<br /><br />
            Since WhatsApp is freely provided by most cell phone carriers in the region, this was the perfect solution to make sure we could reach the entire population in all countries — completely free of charge to the end user."
          />

          <InfoRowRight
            infoImgR={require("../assets/img-3-contenido-gatorade.png")}
            infoTitleR="Result"
            infoTextR="The campaign launched in over 9 countries in South and Central America from Argentina to Mexico.<br /><br />
            With over 35,000 participants, Gatorade is now retooling this WhatsApp initiative as constant point of contact for sport enthusiasts in the region."
          />

          <ContactForm />

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Gatorade;
