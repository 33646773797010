import React, {useEffect} from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import ItemSlider from '../components/itemSlider'
import Awards from '../components/awards'
import SkillRowRight from '../components/skillRowRight'
import SkillRowLeft from '../components/skillRowLeft'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ContactForm from '../components/contactForm';

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "MDS - Home";
    }, [])

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
  return (
    <div className="App Home" id='hide'>

    <Header
        logo={require("../assets/logo.png")}
    />
    <Slider {...settings} className="slider_home">
        <ItemSlider
            typeProjectSlider="video"
            imageProjectSlider={require("../assets/mds_coverlanding.mp4")}
            titleProjetSlider={`We help brands create impactful digital experiences rooted in emerging technologies `}
            descriptionProjetSlider=" that make users connect with their deepest emotions in the most unexpected and playful ways."
        />
    </Slider>
    <div className="section-1">
        <div className="container special">
            <SkillRowLeft
                infoImgL={require("../assets/miller.png")}
                infoTitleL="MILLER TIMELESS COLLECTION"
                infoTextL="Developed coming out of the pandemic, six championship-style rings adorned with precious gems using NFC tags to unlock free beer when they're near each other, interfacing with Apple Wallet to inject a digital card."
                linkSkill="/miller"
                bgColor={'orange'}
            />
            <SkillRowRight
                infoImgR={require("../assets/you.png")}
                infoTitleR="TOYOTA"
                infoTextR="Winner of three Clio awards, this music video (which was created specifically for this campaign), became the official music video for Lunay and Anittas’ “Todo o Nada” collaboration."
                linkSkill="/toyota"
                textSkill="see projects"
                bgColor={'gray'}
            />
            <Slider {...settings} className="slider_home">
                <ItemSlider
                    typeProjectSlider="image"
                    imageProjectSlider={require("../assets/banner-new.jpg")}
                    titleProjetSlider="EFFICIENCY <br/> QUALITY <br/> & CREATIVITY"
                    descriptionProjetSlider="An Innovation HUB powered by diverse, remotely-connected teams, dedicated to bringing your projects to life with unmatched efficiency allowing brands make the difference through powerful experiences meant to create an unique connection for their audiences."
                    descriptionProjetSlider2="Our multi-talented team experiments with emerging technologies such as software languages, robotics, VR, AR and AI to turn ideas into real projects rooted in the understanding of the consumers, experiences that shape culture and help brands move forward."
                />
            </Slider>
            <SkillRowLeft
                infoImgL={require("../assets/starburst2.jpg")}
                infoTitleL="STARBURST"
                infoTextL="Since no one on Earth can answer why Starburst is so juicy, we decided to ask civilizations in outer space. How can we communicate with beings from other parts of the universe to find the answer?"
                linkSkill="/starburst"
                textSkill="see projects"
                bgColor={'red'}
            />
            <SkillRowRight
                infoImgR={require("../assets/skitters.png")}
                infoTitleR="SKITTLES"
                infoTextR="Nine years ago, Skittles removed its Lime flavor, upsetting many fans. To re-launch Lime, the brand took an unusual approach by using the online anger directed at them. We helped them apologize individually to all 138,880 people who complained."
                linkSkill="/skittles"
                textSkill="see projects"
                bgColor={'whiteGray'}
            />
            
            <Awards />

            <div className='boxBot'>
                <h2>
                    We are curious, we like to play, we like challenges, we are restless...
                </h2>
                <div className='boxBotInfo'>
                    <h3>On our downtime, we don’t Netflix and chill, we keep experimenting.</h3>
                    <p>
                        Taking into account the political climate in the US at the moment, and knowing that we needed to find a way to analyze content in real time, the best option was to analyze President Trump’s tweets.
                    </p>
                    <p>
                        That’s why we created potusmood.com, a website which would analyze the President’s feelings in real time and display them on a website. This, was accompanied by a physical “Mood lamp” which would change colors/demeanor as the President tweeted and his feelings expressed via his favorite medium: tweets.
                    </p>
                </div>
            </div>
            
            <ContactForm />
        </div>
    </div>

    <Footer />
    </div>
  );
}

export default Home;
