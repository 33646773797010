import React from "react";

import AOS from 'aos';
import 'aos/dist/aos.css';

const BannerIntern = (props) => {
    
    AOS.init();
    return(
        <section className="bannerTop" data-aos="fade-zoom-in" style={{ backgroundImage:`url(${props.bgBanner})` }}>
            <img className={props.className} src={props.logoBanner} alt="MDS" />
        </section>
    );
        
}

export default BannerIntern;