import React, {useEffect, useState} from 'react';

import Header from '../components/header'
import Footer from '../components/footer'
import BannerSuccessStoriesR from '../components/bannerSuccessStoriesR'

const width = window.innerWidth;
const breakpoint = 769;


function Home() {
    const [isVisible, setIsVisible] = useState(true);
    const [, setHeight] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "MDS - Projects";
    }, [])

    const listenToScroll = () => {
        let heightToHideFrom = window.innerHeight * 3;
        console.log(heightToHideFrom);
        const winScroll = document.body.scrollTop || 
            document.documentElement.scrollTop;
        setHeight(winScroll);
    
        if (winScroll > heightToHideFrom) {  
             isVisible && setIsVisible(false);
        } else {
             setIsVisible(true);
        }  
    };
    useEffect(() => {   
        window.addEventListener("scroll", listenToScroll);
        return () => 
            window.removeEventListener("scroll", listenToScroll); 
    }, []);
  return (
    <div className="App Home" id='hide'>

        <Header
            logo={require("../assets/logo.png")}
        />

        <BannerSuccessStoriesR
            bgBannerProduct={width < breakpoint ? require("../assets/bannerProduct/bg-miller-mob.png") : require("../assets/bannerProduct/mask-img-bg-miller.png")}
            infoLogoProduct={require("../assets/logoBanner/timelessLockupStacked.png")}
            classNameImg="miller"
            classCut="right-cont"
            infoText="We created an application which was to be used only by 6 people in the world since the only way to unlock it is by using the rings provided to the winner."
            infoLink="Read more"
            productLink="/projects/miller"
            fade_direction="fade-right"
        />
        <BannerSuccessStoriesR
            bgBannerProduct={ width < breakpoint ? require("../assets/bannerProduct/bg-gatorade-mob.png") : require("../assets/bannerProduct/bg-gatorade.png")}
            infoLogoProduct={require("../assets/logoBanner/logo-gatorade.png")}
            classNameImg="gatorade"
            infoText="With over 35,000 participants, Gatorade is now retooling this WhatsApp initiative as constant point of contact for sport enthusiasts in the region."
            infoLink="Read more"
            productLink="/projects/gatorade"
            classCut="cutInfo left-cont"
            classInfoL="InfoL"
            fade_direction="fade-left"
        />
        <BannerSuccessStoriesR
            bgBannerProduct={ width < breakpoint ? require("../assets/bannerProduct/bg-toyota-mob.png") : require("../assets/bannerProduct/bg-toyota.png")}
            infoLogoProduct={require("../assets/logoBanner/logo-toyota.png")}
            classNameImg="toyota"
            infoText='The music video (which was created specifically for this campaign), became the official music video for Lunay and Anittas’ “Todo o Nada” collaboration.'
            infoLink="Read more"
            productLink="/projects/toyota"
            classCut="cutInfo right-cont"
            fade_direction="fade-right"
        />
        <BannerSuccessStoriesR
            bgBannerProduct={ width < breakpoint ? require("../assets/bannerProduct/bg-skittles-mob.png") : require("../assets/bannerProduct/bg-skittles.png")}
            infoLogoProduct={require("../assets/logoBanner/skittles-brand.png")}
            classNameImg="skittles"
            infoText="Over 65,000 tweets were created by the users, more than 900,000 final tweets will be analyzed for content and a big surprise will come out at the end of the campaign in early March."
            infoLink="Read more"
            productLink="/projects/skittles"
            classCut="cutInfo left-cont"
            classInfoL="InfoL"
            fade_direction="fade-left"
        />
        <BannerSuccessStoriesR
            bgBannerProduct={width < breakpoint ? require("../assets/bannerProduct/bg-netflix-mob.png") : require("../assets/bannerProduct/bg-netflix.png")}
            infoLogoProduct={require("../assets/logoBanner/brand-netflix.png")}
            classNameImg="netflix"
            infoText="Allow Netflix to know more about the perception of their publications on their social networks (specifically Instagram) and provide valuable insights not provided by the standard analytics supplied by the social network."
            infoLink="Read more"
            productLink="/projects/netflix"
            classCut="cutInfo right-cont"
            fade_direction="fade-right"
        />
        <BannerSuccessStoriesR
            bgBannerProduct={ width < breakpoint ? require("../assets/bannerProduct/bg-amarillo-mob.png") : require("../assets/bannerProduct/bg-us-amarillo.png")}
            infoLogoProduct={require("../assets/logoBanner/logo-amarillo.png")}
            classNameImg="amarillo"
            infoText="One of the most recent Colombian “Soonicorns” (Soon to be Unicorn), works with the MDS team for all their digital communication needs.  Ever since becoming their digital AOR, our team has been in charge of working with the “buy now, pay later” credit solution startup for anything digital in both Colombia and Brazil."
            infoLink=""
            productLink=""
            classCut="cutInfo left-cont"
            classInfoL="InfoL"
            fade_direction="fade-left"
        />
        <BannerSuccessStoriesR
            bgBannerProduct={width < breakpoint ? require("../assets/bannerProduct/bg-us-addi-mob.png") : require("../assets/bannerProduct/bg-addi.png")}
            infoLogoProduct={require("../assets/logoBanner/addi-brand.png")}
            classNameImg="addi"
            infoText="One of the most recent Colombian “Soonicorns” (Soon to be Unicorn), works with the MDS team for all their digital communication needs.  Ever since becoming their digital AOR, our team has been in charge of working with the “buy now, pay later” credit solution startup for anything digital in both Colombia and Brazil."
            infoLink=""
            productLink=""
            classCut="cutInfo right-cont"
            fade_direction="fade-right"
        />

        <Footer />
    </div>
  );
}

export default Home;
